module.exports = [{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-styled-components@4.15.0_babel-plugin-styled-components@1.13.3_gatsby@4.12.1_re_cc4czir6byntznqfcfx7pugqx4/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"topLevelImportPaths":["@xstyled/styled-components"],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-remark-images@5.12.0_gatsby-plugin-sharp@3.15.0_gatsby@4.12.1/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-catch-links@3.15.0_gatsby@4.12.1/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-mdx@2.15.0_@mdx-js+mdx@1.6.22_@mdx-js+react@1.6.22_gatsby@4.12.1_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/Users/david/repos/use-gesture/node_modules/.pnpm/smooth-doc@8.1.0_@babel+core@7.22.19_core-js@3.32.2_gatsby@4.12.1_graphql@15.8.0_react-dom@18_ndi47mu2cjfitbvv7lxufngemi/node_modules/smooth-doc/src/plugins/gatsby-remark-autolink-headers/index.js"},{"resolve":"gatsby-remark-images","options":{"maxWidth":1200}}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/david/repos/use-gesture/documentation","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../../node_modules/.pnpm/smooth-doc@8.1.0_@babel+core@7.22.19_core-js@3.32.2_gatsby@4.12.1_graphql@15.8.0_react-dom@18_ndi47mu2cjfitbvv7lxufngemi/node_modules/smooth-doc/src/plugins/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-manifest@3.15.0_gatsby@4.12.1_graphql@15.8.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"@use-gesture documentation","short_name":"@use-gesture documentation","start_url":"/","display":"minimal-ui","icon":"images/logo-manifest.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a2ec2f9235e6aa90a4403808b4f24dab"},
    },{
      plugin: require('../../node_modules/.pnpm/smooth-doc@8.1.0_@babel+core@7.22.19_core-js@3.32.2_gatsby@4.12.1_graphql@15.8.0_react-dom@18_ndi47mu2cjfitbvv7lxufngemi/node_modules/smooth-doc/gatsby-browser.js'),
      options: {"plugins":[],"name":"@use-gesture documentation","siteUrl":"https://use-gesture.netlify.app","description":"@use-gesture allows you to implement advanced UI interactions with just a few lines of code.","author":"David Bismut","sections":["About","Reference","More"],"navItems":[{"title":"Docs","url":"/docs/"},{"title":"API","url":"/docs/gestures/"}],"docSearch":{"apiKey":"03ac4140f84a905c678b2501a118eb94","appId":"1UFCYC8N2G","indexName":"use-gesture"},"twitterAccount":"pmndrs","githubRepositoryURL":"https://github.com/pmndrs/use-gesture","carbonAdsURL":"//cdn.carbonads.com/carbon.js?serve=CEAI42QM&placement=use-gesturenetlifyapp"},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-google-analytics@4.24.0_gatsby@4.12.1_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-162749258-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
