import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><img parentName="p" {...{
        "src": "https://img.shields.io/npm/v/@use-gesture/core?style=flat&colorA=000000&colorB=000000",
        "alt": "npm (tag)"
      }}></img>{` `}<img parentName="p" {...{
        "src": "https://img.shields.io/bundlephobia/minzip/@use-gesture/core?style=flat&colorA=000000&colorB=000000",
        "alt": "npm bundle\n  size"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "https://img.shields.io/npm/l/@use-gesture/core?style=flat&colorA=000000&colorB=000000",
        "alt": "NPM"
      }}></img>{`
`}<a parentName="p" {...{
        "href": "https://discord.gg/ZZjjNvJ"
      }}><img parentName="a" {...{
          "src": "https://img.shields.io/discord/740090768164651008?style=flat&colorA=000000&colorB=000000&label=discord&logo=discord&logoColor=ffffff",
          "alt": "Discord\n  Shield"
        }}></img></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      