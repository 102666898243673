import { x } from '@xstyled/styled-components';
import { Article, ScreenContainer, Button } from "../../../../../node_modules/.pnpm/smooth-doc@8.1.0_@babel+core@7.22.19_core-js@3.32.2_gatsby@4.12.1_graphql@15.8.0_react-dom@18_ndi47mu2cjfitbvv7lxufngemi/node_modules/smooth-doc/components";
import { Link } from 'gatsby';
import notFoundImageURL from "../../../../../node_modules/.pnpm/smooth-doc@8.1.0_@babel+core@7.22.19_core-js@3.32.2_gatsby@4.12.1_graphql@15.8.0_react-dom@18_ndi47mu2cjfitbvv7lxufngemi/node_modules/smooth-doc/images/404.png";
import * as React from 'react';
export default {
  x,
  Article,
  ScreenContainer,
  Button,
  Link,
  notFoundImageURL,
  React
};