import { Button, HeroSection, HeroBody, HeroTeaser, HeroActionList, HeroAction, FeatureSection, FeatureList, Feature, FeatureImage, FeatureTitle, FeatureText, ScreenContainer } from 'smooth-doc/components';
import { Link } from 'gatsby';
import Sandbox from "../../../../pages/HeroSandbox";
import Title from "../../../../pages/HeroSandbox/Title";
import UseGesture from "../../../../pages/docs/code/rug";
import compassImageURL from "../../../../images/compass.svg";
import gameImageURL from "../../../../images/game.svg";
import toolsImageURL from "../../../../images/tools.svg";
import Shields from "../../../../pages/partials/shields";
import * as React from 'react';
export default {
  Button,
  HeroSection,
  HeroBody,
  HeroTeaser,
  HeroActionList,
  HeroAction,
  FeatureSection,
  FeatureList,
  Feature,
  FeatureImage,
  FeatureTitle,
  FeatureText,
  ScreenContainer,
  Link,
  Sandbox,
  Title,
  UseGesture,
  compassImageURL,
  gameImageURL,
  toolsImageURL,
  Shields,
  React
};