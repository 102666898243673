import Code from "../../../../pages/docs/code";
import UseGesture from "../../../../pages/docs/code/rug";
import Specs from "../../../../pages/docs/code/Specs";
import { CodeSnippet, TabPanel } from "../../../../pages/docs/CodeSnippet";
import * as React from 'react';
export default {
  Code,
  UseGesture,
  Specs,
  CodeSnippet,
  TabPanel,
  React
};