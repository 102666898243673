// extracted by mini-css-extract-plugin
export var Axis = "styles-module--Axis--sdXwu";
export var Axis2 = "styles-module--Axis2--24UMQ";
export var Bounds = "styles-module--Bounds--ETL02";
export var CaptureFalse = "styles-module--CaptureFalse--2fKTR";
export var Delay = "styles-module--Delay--GClHW";
export var FilterTaps = "styles-module--FilterTaps--qrXF2";
export var Initial = "styles-module--Initial--xqltf";
export var LethargyWheel = "styles-module--LethargyWheel--YBgh9";
export var PointerTrue = "styles-module--PointerTrue--pI08b";
export var PreventDefault = "styles-module--PreventDefault--rFVv9";
export var PreventScroll = "styles-module--PreventScroll--8VVkP";
export var Rubberband = "styles-module--Rubberband--vITGg";
export var Swipe = "styles-module--Swipe--BWW1k";
export var Threshold = "styles-module--Threshold--JpOz9";
export var TouchAction = "styles-module--TouchAction--vmHPt";
export var Transform = "styles-module--Transform--6BV0a";
export var active = "styles-module--active--xpFPg";
export var cancelLimit = "styles-module--cancelLimit--O2Clw";
export var drag = "styles-module--drag--6ib0d";
export var easter = "styles-module--easter--Mgyq7";
export var example = "styles-module--example--WybXb";
export var from = "styles-module--from--pZ0x0";
export var horizontal = "styles-module--horizontal--tw5Ft";
export var limits = "styles-module--limits--gF0oB";
export var link = "styles-module--link--adKaZ";
export var overlay = "styles-module--overlay--BffEv";
export var scroll = "styles-module--scroll--jt6xa";
export var square = "styles-module--square--mNmgC";
export var target = "styles-module--target--QHxQl";
export var ui = "styles-module--ui--8YTkn";